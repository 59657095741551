<template>
  <div class="app">
    <div class="height80"></div>
    <div class="flex-sb">
      <div class="title">学生权限开通</div>
      <div class="div"><el-button type="success" size="20" @click="handleRouter('/studentlist')">学生列表</el-button></div>
    </div>

    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="学生姓名：">
        <el-input v-model="form.username" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input v-model="form.phone" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="密码：">
        <el-input v-model="form.password" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="视频查询：">
        <!-- <el-checkbox-group v-model="form.resource">
          <el-checkbox :label="item.id" v-for="(item, index) in label" :key="index">{{ item.name }}</el-checkbox>
        </el-checkbox-group> -->
        <el-select v-model="form.resource" multiple filterable remote reserve-keyword  placeholder="请输入关键词(可搜索)"
          :remote-method="remoteMethod" :loading="loading" >
          <el-option v-for="item in options" :key="item.name" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="观看次数">
        <el-input v-model="form.number" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input type="textarea" v-model="form.desc" :rows="4"></el-input>
      </el-form-item>
      <el-button type="primary" @click="onSubmit" size="40" style="margin-left: 120px">创建用户</el-button>
    </el-form>
  </div>
</template>
<script>
import { VideoList, addUser, TypeVideo } from "@/api/user"
export default {
  name: "Video",
  data() {
    return {
      loading: false,
      options: [],
      label: "",
      form: {
        username:"",
        phone: "",
        resource: [],
        password: "",
        number: "",
        desc: "",
      },
    }
  },
  mounted() {
    this.HandleData();
  },
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.label.filter(item => {
            return item.name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },


    HandleData() {
      TypeVideo().then(res => {
        console.log(res, 222)
        if (res.code == 200) {
          this.label = res.data;
        }
      });
    },
    onSubmit() {
      let { phone, password, number, desc,username } = this.form;
        // console.log(this.form);
        if (!phone || !password) {
        this.$message({
          showClose: true,
          message: "学生信息不完整",
          type: "error",
        });
        return;
      }
      const str = this.form.resource.join();
      // console.log(str)
      addUser({ phone: phone, password: password, count: number, type_ids: str,username,username }).then(res => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "新用户创建成功",
            type: "success",
          });
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-select>.el-input{
  width: 400px;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
}

.height80 {
  width: 100%;
  height: 60px;
}

.title {
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: bold;
}

.app {
  width: 800px;
  margin: 0 auto;
}
</style>